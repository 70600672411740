import logo from './logo.svg';
import React from 'react';
import {useState} from 'react'
import './schoolofathens.jpeg'
import {
  Button,
  Form, 
  TextArea
} from 'semantic-ui-react';
import './App.css';



import { Configuration, OpenAIApi } from "openai";
import { RequiredError } from 'openai/dist/base';


export default function Translate() {


    const [inputText, setInputText] = useState('');
    const [resultText, setResultText] = useState('');
    const [result, setResult] = useState('');


    const configuration = new Configuration({
        apiKey: "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",
    });

    const openai = new OpenAIApi(configuration);

    const [resultImg, setResultImg] = useState("");

    const generateOpenAIimage = async () => {
        const res = await openai.createImage({
            prompt: "An abstract image without text of " + inputText,
            n: 1,
            size: "512x512",
        });
        setResult(res.data.data[0].url);
    };

    const getOpenAIresponse = () => {
        openai.createCompletion({
            model: "text-davinci-003",
            prompt: "You are a chatbot that recommends articles and books to users. A user will enter some topics that they want to learn about and you will tell them some corresponding relevant books and authors. \nHere are the topics the user is interested in: " + inputText + "\n Recommended books:",
            max_tokens: 4000,
            temperature: 0.7,
        }).then((response) => {
            setResultText(response.data.choices[0].text)
        })
    };

    return (
        <div>
            <div className="app-header">
                <h2 className="header">The LearnEngine</h2>
                <h4 className="paragraph">Want to start learning about something but not sure where to start? Enter some topics and we will recommend some cool articles, books, and papers for you! </h4>
            </div>

            <div>
                <img src={require('./schoolofathens.jpeg')} className="header-img" alt="school of athens"/>
            </div>

            <div className='app-body'>
                <div>
                    <Form>
                        <Form.Field 
                            control={TextArea}
                            placeholder='Write topics, subjects, authors, type of book, ... '
                            onChange={(e) => setInputText(e.target.value)}
                        />

                        <Button class ="ui button"
                            color="blue"
                            size="large"
                            onClick={() => {getOpenAIresponse(); generateOpenAIimage();}}
                        >
                            Get Knowledge</Button>


                        <Form.Field 
                            control={TextArea}
                            placeholder='Your recommendations will display here...'
                            value={resultText}
                        />

                    </Form>
                </div>

                {result.length > 0 ? (
          <img className="result-image" src={result} alt="result" />
        ) : (
          <></>
        )}

                <br></br>
                <p>Example input: <i>"Metaphysics, quantum mechanics, string theory, history of the universe"</i></p>
                <p>Example output: <br></br> <b>Metaphysics</b>: 
"Metaphysics" by Aristotle, 
"The Concept of Nature" by Alfred North Whitehead <br></br>

<b>Quantum Mechanics</b>: 
"The Quantum World: Quantum Physics for Everyone" by Kenneth W. Ford, 
"Quantum Mechanics: The Theoretical Minimum" by Leonard Susskind <br></br>

<b>String Theory</b>: 
"The Elegant Universe: Superstrings, Hidden Dimensions, and the Quest for the Ultimate Theory" by Brian Greene, 
"A First Course in String Theory" by Barton Zwiebach <br></br>

<b>History of the Universe</b>: 
"A Brief History of Time: From the Big Bang to Black Holes" by Stephen Hawking, 
"The Universe in a Nutshell" by Stephen Hawking. <br></br></p>
            </div>
        </div>

        
    )
}

